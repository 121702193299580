import { render, staticRenderFns } from "./SignOffHistoryGrid.vue?vue&type=template&id=2b789512&scoped=true"
import script from "./SignOffHistoryGrid.vue?vue&type=script&lang=tsx"
export * from "./SignOffHistoryGrid.vue?vue&type=script&lang=tsx"
import style0 from "./SignOffHistoryGrid.vue?vue&type=style&index=0&id=2b789512&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b789512",
  null
  
)

export default component.exports