
import { Component, Vue } from 'vue-property-decorator';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import FullScreenForm from '@/components/FullScreenForm.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import { RawLocation } from 'vue-router';
import { RouteNames } from '@/constants';
import ValidatedForm from '@/components/form/ValidatedForm.vue';
import { RpaOpsModule } from '@/features/om27/store/om27.module';
import {
  LogicalBot,
  SignOffFilters,
  SignOffHistoryItem,
  UserPreferences,
} from '@/features/om27/types/om27.types';
import DataGrid from '@/features/om27/components/DataGrid.vue';
import { Promised } from 'vue-promised';
import { ValidationObserver } from 'vee-validate';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import { formatDate } from '@/features/om27/utils/datetime';
import SignOffHistoryGrid from '@/features/om27/components/SignOffHistoryGrid.vue';

@Component({
  components: {
    Grid,
    GridCell,
    FullScreenForm,
    GButton,
    ValidatedForm,
    ValidationObserver,
    DataGrid,
    Promised,
    GDialog,
    SignOffHistoryGrid,
  },
})
export default class SignOff extends Vue {
  closeRoute: RawLocation = {
    name: RouteNames.Om27,
  };

  get timezone(): UserPreferences['preferred_timezone'] {
    return RpaOpsModule.userPreferences.preferred_timezone;
  }

  get timezone_short(): UserPreferences['timezone_short'] {
    return RpaOpsModule.userPreferences.timezone_short;
  }

  get logicalBotId(): number {
    return parseInt(this.$route.params.logicalBotId, 10);
  }

  get logicalBot(): LogicalBot | undefined {
    return RpaOpsModule.logicalBots.find(bot => bot.logicalBotId === this.logicalBotId);
  }

  get signOffDate(): string {
    const date = this.logicalBot?.currentSignOffNeededByDate;
    if (date) {
      return formatDate(date, this.timezone, {
        dateOnly: true,
        timeOnly: false,
        timezoneAbbrv: this.timezone_short,
      });
    }
    return '';
  }

  get signOffId() {
    return this.logicalBot?.signOffId || 0;
  }

  get bots(): LogicalBot[] {
    if (this.logicalBot) {
      return [this.logicalBot];
    }

    return [];
  }

  get selectedBots(): number[] {
    return this.bots.map(bot => bot.logicalBotId);
  }

  goToDashboard(): void {
    this.$router
      .replace({
        name: RouteNames.Om27,
        query: this.$route.query || {
          expand: this.logicalBotId.toString(),
        },
      })
      .catch(() => void 0);
  }

  filters: SignOffFilters = {
    machineNameShort: [],
    fileName: [],
  };
  activeFilters: SignOffFilters = {
    machineNameShort: [],
    fileName: [],
  };

  promise: Promise<unknown> | null = null;
  signOffPromise: Promise<SignOffHistoryItem[]> | null = null;
  async created(): Promise<void> {
    if (!RpaOpsModule.logicalBots.length) {
      this.promise = RpaOpsModule.getDashboard();
    } else {
      this.promise = Promise.resolve();
    }

    await this.promise;

    if (!this.logicalBot) {
      this.goToDashboard();
    }

    this.signOffPromise = RpaOpsModule.getSignOffHistoryData(this.logicalBotId);
  }
}
